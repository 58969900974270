export const getFakeAmount = (value: number) => {
    const numbers = '0123456789';
    // Zjistíme, zda bylo původní číslo záporné
    const isNegative = value < 0;

    // Použijeme absolutní hodnotu čísla pro zajištění správné délky celé části
    const parts = Math.abs(value).toString().split('.');
    const integerLength = parts[0].length; // Délka celé části
    const decimalLength = parts.length > 1 ? parts[1].length : 0; // Délka desetinné části
    let result = '';

    // Generujeme náhodná čísla pro celou část
    for (let i = 0; i < integerLength; i += 1) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    // Přidáme desetinnou část, pokud je potřeba
    if (decimalLength > 0) {
        result += '.';
        for (let i = 0; i < decimalLength; i += 1) {
            result += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
    }

    // Pokud bylo původní číslo záporné, přidáme znaménko mínus
    if (isNegative) {
        result = `-${result}`;
    }

    // Převedeme řetězec zpět na číslo
    return parseFloat(result);
};
