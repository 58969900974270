import styled, { css, DefaultTheme } from 'styled-components';
import { IconSymbolResponsiveSizeType, IconSymbolSizeType } from '../../../../../theme/type/icon-symbol-type';
import { BreakpointSizeType } from '../../../../../theme/type/breakpoints-type';
import { getOpacity } from '../../../../../theme/utils/get-rgb';

const getCssSkeletonIconSize = (sizeKey: IconSymbolSizeType, theme: DefaultTheme) => `
    height: ${theme.iconSymbol.size[sizeKey].wrapper};
    min-height: ${theme.iconSymbol.size[sizeKey].wrapper};
    width: ${theme.iconSymbol.size[sizeKey].wrapper};
    min-width: ${theme.iconSymbol.size[sizeKey].wrapper};
`;

const getResponsiveSkeletonIconSize = (size: IconSymbolResponsiveSizeType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as IconSymbolSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssSkeletonIconSize(iconSizeKey, theme)}
                }
            `;
        }

        return `       
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssSkeletonIconSize(iconSizeKey, theme)}
            }
        `;
    });
};
export const SkeletonWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size', 'radius'].includes(prop),
})<{
    size: IconSymbolSizeType | IconSymbolResponsiveSizeType | undefined;
    radius?: number;
}>`
    ${({ theme, size, radius }) => css`
        display: inline-flex;
        background-color: ${getOpacity(theme.palette.color.default.dark, 0.3)};
        border-radius: ${`${radius}rem`};
        overflow: hidden;
        position: relative;

        &:after {
            background: linear-gradient(90deg, transparent, ${theme.palette.color.default.opacity.high}, transparent);
            content: '';
            position: absolute;
            transform: translateX(-100%);
            inset: 0;
            animation: 1.5s linear 0.5s infinite normal none running wave;
        }

        @keyframes wave {
            0% {
                transform: translateX(-100%);
            }
            50% {
                transform: translateX(0%);
            }
            100% {
                transform: translateX(100%);
            }
        }

        ${size &&
        typeof size === 'string' &&
        css`
            ${getCssSkeletonIconSize(size, theme)};
        `}

        ${size &&
        typeof size === 'object' &&
        css`
            ${getResponsiveSkeletonIconSize(size, theme)};
        `}
    `}
`;
