import styled, { DefaultTheme, css } from 'styled-components';
import { IconSymbolResponsiveSizeType, IconSymbolSizeType } from '../../../../theme/type/icon-symbol-type';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { IconButtonSizeType } from '../../../../theme/type/icon-button-type';

const getCssSymbolSize = (sizeKey: IconSymbolSizeType, theme: DefaultTheme) => `
    height: ${theme.iconSymbol.size[sizeKey].wrapper};
    width: ${theme.iconSymbol.size[sizeKey].wrapper};
`;

const getResponsiveSymbolSize = (size: IconSymbolResponsiveSizeType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as IconSymbolSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssSymbolSize(iconSizeKey, theme)}
                }
            `;
        }

        return `       
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssSymbolSize(iconSizeKey, theme)}
            }
        `;
    });
};

const getSymbolSize = (size: IconSymbolSizeType | IconSymbolResponsiveSizeType, theme: DefaultTheme) => css`
    ${typeof size === 'string' &&
    css`
        ${getCssSymbolSize(size, theme)};
    `}

    ${typeof size === 'object' &&
    css`
        ${getResponsiveSymbolSize(size, theme)};
    `}
`;

const getCssSymbolInnerSize = (sizeKey: IconButtonSizeType, theme: DefaultTheme) => `
    width: ${theme.iconSymbol.size[sizeKey].image};
    min-width: ${theme.iconSymbol.size[sizeKey].image};
    height: ${theme.iconSymbol.size[sizeKey].image};
`;

const getResponsiveSymbolInnerSize = (size: IconSymbolResponsiveSizeType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as IconSymbolSizeType;

        if (index === 0) {
            return `
                @media screen and (min-width: 0px) {
                    ${getCssSymbolInnerSize(iconSizeKey, theme)}
                }
            `;
        }

        return `
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssSymbolInnerSize(iconSizeKey, theme)}
            }
        `;
    });
};

const getSymbolInnerSize = (size: IconSymbolSizeType | IconSymbolResponsiveSizeType, theme: DefaultTheme) => css`
    ${typeof size === 'string' &&
    css`
        ${getCssSymbolInnerSize(size, theme)};
    `}

    ${typeof size === 'object' &&
    css`
        ${getResponsiveSymbolInnerSize(size, theme)};
    `}
`;

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size', 'border', 'shadow'].includes(prop),
})<{
    size: IconSymbolSizeType | IconSymbolResponsiveSizeType;
    border?: boolean;
    shadow?: boolean;
}>`
    ${({ theme, size, border, shadow }) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: ${theme.palette.common.textMuted};
        font-size: ${theme.text.size.small.fontSize};
        line-height: ${theme.text.size.small.fontSize};
        font-weight: 900;
        text-decoration: none;
        font-style: normal;
        overflow: hidden;

        background: ${theme.palette.color.white.main};

        ${border &&
        css`
            border: 0.1rem solid ${theme.card.variant.default.border};
            border-radius: ${theme.radius.small};
        `}

        ${shadow &&
        css`
            box-shadow: ${theme.shadow.small};
        `}

        ${getSymbolSize(size, theme)};
    `}
`;

export const Inner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size'].includes(prop),
})<{
    size: IconSymbolSizeType | IconSymbolResponsiveSizeType;
}>`
    ${({ theme, size }) => css`
        position: relative;

        ${getSymbolInnerSize(size, theme)};
    `}
`;
