import React from 'react';
import { SkeletonWrapper } from './skeleton-icon-symbol.styled';
import { IconSymbolResponsiveSizeType, IconSymbolSizeType } from '../../../../../theme/type/icon-symbol-type';

type SkeletonIconSymbolType = {
    size: IconSymbolSizeType | IconSymbolResponsiveSizeType | undefined;
    radius?: number;
};

const SkeletonIconSymbol: React.FC<SkeletonIconSymbolType> = ({ size, radius = 0.5 }: SkeletonIconSymbolType) => {
    return <SkeletonWrapper size={size} radius={radius} />;
};

export default SkeletonIconSymbol;
