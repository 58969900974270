import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconEmoticonSad: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M18.3164 21.3095C16.3741 22.6273 14.0682 23.3046 11.7218 23.2466C9.37529 23.1885 7.10574 22.398 5.23098 20.9857C3.35622 19.5734 1.97014 17.6101 1.26686 15.3708C0.563585 13.1314 0.57834 10.7282 1.30906 8.49767C2.03978 6.26713 3.44987 4.321 5.34183 2.93185C7.23379 1.5427 9.51287 0.780109 11.8599 0.750873C14.2069 0.721637 16.5042 1.42722 18.4302 2.76882C20.3562 4.11041 21.8143 6.0208 22.6004 8.23245"
        />
        <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M8.24866 11.2484C8.04155 11.2484 7.87366 11.0805 7.87366 10.8734C7.87366 10.6663 8.04155 10.4984 8.24866 10.4984"
        />
        <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M8.24866 11.2484C8.45576 11.2484 8.62366 11.0805 8.62366 10.8734C8.62366 10.6663 8.45576 10.4984 8.24866 10.4984"
        />
        <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M15.7487 11.2484C15.5416 11.2484 15.3737 11.0805 15.3737 10.8734C15.3737 10.6663 15.5416 10.4984 15.7487 10.4984"
        />
        <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M15.7487 11.2484C15.9558 11.2484 16.1237 11.0805 16.1237 10.8734C16.1237 10.6663 15.9558 10.4984 15.7487 10.4984"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M15.7267 18.7484C15.839 18.7492 15.95 18.7252 16.052 18.6781C16.1539 18.6311 16.2443 18.5621 16.3166 18.4762C16.3888 18.3903 16.4413 18.2895 16.4702 18.181C16.499 18.0724 16.5037 17.9589 16.4837 17.8484C16.2564 16.8284 15.6882 15.9163 14.8728 15.2627C14.0575 14.609 13.0437 14.2528 11.9987 14.2528C10.9536 14.2528 9.93984 14.609 9.12449 15.2627C8.30914 15.9163 7.74096 16.8284 7.51367 17.8484C7.49368 17.9589 7.49829 18.0724 7.52717 18.181C7.55606 18.2895 7.60851 18.3903 7.68079 18.4762C7.75307 18.5621 7.8434 18.6311 7.94535 18.6781C8.04731 18.7252 8.15839 18.7492 8.27067 18.7484C9.45121 18.2533 10.7185 17.9984 11.9987 17.9984C13.2788 17.9984 14.5461 18.2533 15.7267 18.7484Z"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M5.24866 8.16447C6.03708 8.3364 6.86059 8.23373 7.58266 7.87347C8.3148 7.53367 8.90892 6.95401 9.26666 6.23047"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M18.8326 8.16447C18.0436 8.32474 17.2238 8.22248 16.4983 7.87329C15.7729 7.52409 15.1816 6.94714 14.8146 6.23047"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M23.243 16.2484C23.2746 16.8779 23.0557 17.4943 22.634 17.9628C22.2124 18.4313 21.6223 18.7138 20.993 18.7484C20.3636 18.7138 19.7736 18.4313 19.352 17.9628C18.9303 17.4943 18.7114 16.8779 18.743 16.2484C18.743 14.8674 20.993 11.2484 20.993 11.2484C20.993 11.2484 23.243 14.8674 23.243 16.2484Z"
        />
    </Icon>
);
