import React, { useMemo } from 'react';
import Link from 'next/link';
import { Description, Content, Name, Wrapper, Value, IconWrapper, Ticker } from './symbol.styled';
import { SymbolIcon } from './icon/symbol-icon';
import { SymbolIconEtf } from './icon/etf/symbol-icon-etf';
import { IconSymbolResponsiveSizeType, IconSymbolSizeType } from '../../../theme/type/icon-symbol-type';
import SkeletonIconSymbol from '../skeleton/icon/symbol/skeleton-icon-symbol';
import { SymbolTypeEnum } from '../../../defs/api';

type SymbolCommonType = {
    skeleton?: boolean;
    size?: IconSymbolSizeType | IconSymbolResponsiveSizeType | undefined;
};

type SymbolSkeletonType = {
    skeleton: true;
    name?: React.ReactNode;
    description?: React.ReactNode;
    value?: React.ReactNode;
    ticker?: React.ReactNode;
};

type SymbolDefaultType = {
    skeleton?: false;
    name?: string;
    symbol: string | undefined;
    onClick?: () => void;
    description?: React.ReactNode | string | null;
    value?: string | null;
    type?: SymbolTypeEnum;
    url?: string;
    ticker?: string;
};

type SymbolType = (SymbolSkeletonType & SymbolCommonType) | (SymbolDefaultType & SymbolCommonType);

export const Symbol: React.FC<SymbolType> = (props) => {
    const { skeleton, size, name, description, value, ticker } = props;

    if (skeleton) {
        return (
            <Wrapper>
                <SkeletonIconSymbol size={size} />
                {(name || ticker || description) && (
                    <Content>
                        {(name || ticker) && (
                            <Name>
                                {name} {ticker && <Ticker>&mdash; {ticker}</Ticker>}
                            </Name>
                        )}
                        {description && <Description>{description}</Description>}
                    </Content>
                )}
                {value && <Value>{value}</Value>}
            </Wrapper>
        );
    }

    const { symbol, onClick, type, url } = props;

    const symbolIcon = useMemo(() => {
        if (type === SymbolTypeEnum.Et) {
            return <SymbolIconEtf size={size} />;
        }

        return <SymbolIcon symbol={symbol} shadow={false} size={size} type={type} />;
    }, [symbol, type]);

    const symbolContent = (
        <Wrapper onClick={onClick}>
            <IconWrapper>{symbolIcon}</IconWrapper>
            {(name || ticker || description) && (
                <Content>
                    {(name || ticker) && (
                        <Name>
                            {name} {ticker && <Ticker>&mdash; {ticker}</Ticker>}
                        </Name>
                    )}
                    {description && <Description>{description}</Description>}
                </Content>
            )}
            {value && <Value>{value}</Value>}
        </Wrapper>
    );

    if (url) {
        return (
            <Link href={url} passHref>
                <a>{symbolContent}</a>
            </Link>
        );
    }

    return symbolContent;
};
