import React from 'react';
import Image from 'next/image';
import { Inner, Wrapper } from './symbol-icon.styled';
import { IconSymbolResponsiveSizeType, IconSymbolSizeType } from '../../../../theme/type/icon-symbol-type';
import { SymbolTypeEnum } from '../../../../defs/api';

interface SymbolIconProps {
    symbol: string | undefined;
    size?: IconSymbolSizeType | IconSymbolResponsiveSizeType;
    type?: SymbolTypeEnum;
    border?: boolean;
    shadow?: boolean;
}

export const SymbolIcon: React.FC<SymbolIconProps> = ({
    symbol,
    size = 'medium',
    border = true,
    shadow = true,
    type,
}) => {
    return (
        <Wrapper size={size} border={border} shadow={shadow}>
            <Inner size={size}>
                {symbol && (
                    <Image
                        src={`${process.env.NEXT_PUBLIC_API_URL}/logo/${encodeURIComponent(symbol)}${
                            type ? `?type=${type}` : ''
                        }`}
                        layout="fill"
                        objectFit="contain"
                        alt={symbol}
                    />
                )}
            </Inner>
        </Wrapper>
    );
};
