import React from 'react';
import { Wrapper, IconWrapper, Content, ButtonWrapper } from './no-data.styled';
import { IconEmoticonSad } from '../icons/emoticon-sad';

interface NoDataPropsInterface {
    children: React.ReactNode;
    action?: React.ReactNode;
    fullWidth?: boolean;
}

export const NoData: React.FC<NoDataPropsInterface> = ({ children, action, fullWidth }: NoDataPropsInterface) => {
    return (
        <Wrapper fullWidth={fullWidth}>
            <IconWrapper>
                <IconEmoticonSad />
            </IconWrapper>
            <Content>{children}</Content>
            {action && <ButtonWrapper>{action}</ButtonWrapper>}
        </Wrapper>
    );
};
