import { getAmount } from '@app/common';

export const formatBigNumber = (amount: number, fractionDigits: number, suffix: string): string => {
    const absAmount = Math.abs(amount);
    const sign = amount < 0 ? '-' : '';

    switch (true) {
        case absAmount >= 1e12:
            return `${sign}${getAmount(absAmount / 1e12, 1, '')} bln ${suffix}`;
        case absAmount >= 1e9:
            return `${sign}${getAmount(absAmount / 1e9, 1, '')} mld ${suffix}`;
        case absAmount >= 1e6:
            return `${sign}${getAmount(absAmount / 1e6, 1, '')} mln ${suffix}`;
        default:
            return getAmount(amount, fractionDigits, suffix);
    }
};
