import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: inline-flex;
        align-items: center;
        gap: ${theme.gap.vsmall};
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Name = styled.span`
    ${({ theme }) => css`
        font-weight: 700;
        color: ${theme.palette.color.secondary.main};
        transition: color ${theme.base.transition};

        a ${Wrapper}:hover & {
            color: ${theme.palette.color.secondary.lighten};
        }
    `}
`;

export const Description = styled.span`
    ${({ theme }) => css`
        font-weight: 500;
        color: ${theme.palette.color.secondary.opacity.low};
        transition: color ${theme.base.transition};

        a ${Wrapper}:hover & {
            color: ${theme.palette.color.secondary.main};
        }
    `}
`;

export const Ticker = styled.span`
    ${({ theme }) => css`
        font-weight: 500;
        color: ${theme.palette.color.secondary.opacity.low};
        transition: color ${theme.base.transition};

        a ${Wrapper}:hover & {
            color: ${theme.palette.color.secondary.main};
        }
    `}
`;

export const IconWrapper = styled.span`
    ${({ theme }) => css`
        transition: box-shadow ${theme.base.transition};
        border-radius: ${theme.radius.small};
        display: flex;

        a ${Wrapper}:hover & {
            box-shadow: ${theme.shadow.large};
        }
    `}
`;

export const Value = styled.div`
    ${({ theme }) => css`
        font-weight: 500;
        color: ${theme.palette.color.secondary.opacity.low};
        margin-left: ${theme.gap.vsmall};
    `}
`;
