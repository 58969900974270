import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inner, Wrapper } from './symbol-icon-etf.styled';
import { IconSymbolResponsiveSizeType, IconSymbolSizeType } from '../../../../../theme/type/icon-symbol-type';

interface SymbolIconEtfProps {
    size?: IconSymbolSizeType | IconSymbolResponsiveSizeType;
    shadow?: boolean;
}

export const SymbolIconEtf: React.FC<SymbolIconEtfProps> = ({ size = 'medium', shadow }) => {
    const { t } = useTranslation();
    return (
        <Wrapper shadow={shadow} size={size}>
            <Inner size={size}>{t('layout.symbols.etf')}</Inner>
        </Wrapper>
    );
};
