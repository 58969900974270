import { TextVariantColorEnum } from '@app/common';

export const getColor = (amount: number, index?: number, last?: boolean): TextVariantColorEnum => {
    if (last && index && index > 5) {
        return TextVariantColorEnum.Secondary;
    }

    if (amount > 0) {
        return TextVariantColorEnum.Positive;
    }

    if (amount < 0) {
        return TextVariantColorEnum.Negative;
    }

    if (amount === 0) {
        return TextVariantColorEnum.Secondary;
    }
    return TextVariantColorEnum.Secondary;
};
